import React from "react";
import Layout from "../layout/layout";
import "./Carriers.css";

import aetna from "../images/carriers/aetna-Logo-1.png";
import aig from "../images/carriers/aig.png";
import optimed from "../images/carriers/OptiMedHealth.jpg";
import kclife from "../images/carriers/kansas-city-life.jpg";
import americo from "../images/carriers/Americo.jpg";
import uhone from "../images/carriers/united-healthcare.jpg";
import pram from "../images/carriers/pram.jpg";
import gwic from "../images/carriers/GWIC-Logo.png";
import transAm from "../images/carriers/transAmerica.png";
import allstate from "../images/carriers/Allstate-Logo.png";
import pivot from "../images/carriers/Pivot-Logo.png";
import manLife from "../images/carriers/ManhattanLife.jpg";
import ameritas from "../images/carriers/Ameritas-Logo.png";
import americasChoice from "../images/carriers/americasChoice.png";
import foresters from "../images/carriers/foresters.png";
import gerber from "../images/carriers/gerber.png";
import hancock from "../images/carriers/johnHan.png";
import lincoln from "../images/carriers/lincoln.png";
import omaha from "../images/carriers/mutual-of-omaha-life-insurance.jpg";
import natLife from "../images/carriers/nationalLife.png";
import netWell from "../images/carriers/netWell.png";
import northAm from "../images/carriers/northAmerican.png";

function Carriers() {
  return (
    <Layout>
      <div className="carriersContent">
        <div className="carrierTop">
          <div className="carrierTopQuote">
            <h3>
              Chase the vison, not money. The money will end up following you.
            </h3>
            <h2>Tony Hesh</h2>
          </div>
        </div>
        <div className="carrierMiddle">
          <div className="carrierInfo">
            <div className="carrierInfoText">
              <h1>Carriers We Work With</h1>
              <hr></hr>
              <a>
                We only represent A-rated carriers, which means that we work
                with the top carriers in the industry. We have established
                strong relationships with our carriers, and they frequently come
                to train our Associates in person. This provides our team with
                an unparalleled level of knowledge and expertise in the products
                and services that we offer, and allows us to pass that knowledge
                along to our customers. Over and above, you can always find
                Meda-specific bonuses that we draw up special for our team based
                on performance. This commitment to continuous advancement is one
                of the many reasons why MedaHealth is the premier choice for
                insurance professionals.
              </a>
            </div>
          </div>
          <div className="carrierLogoWrapper">
            <div className="carrierLogoContainer">
              <img id="carrierLogos" className="div1" src={aetna}></img>
              <img id="carrierLogos" className="div2" src={aig}></img>
              <img id="carrierLogos" className="div3" src={allstate}></img>
              <img
                id="carrierLogos"
                className="div4"
                src={americasChoice}
              ></img>
              <img id="carrierLogos" className="div5" src={americo}></img>
              <img id="carrierLogos" className="div6" src={ameritas}></img>
              <img id="carrierLogos" className="div7" src={foresters}></img>
              <img id="carrierLogos" className="div8" src={gerber}></img>
              <img id="carrierLogos" className="div9" src={gwic}></img>
              <img id="carrierLogos" className="div10" src={hancock}></img>
              <img id="carrierLogos" className="div11" src={lincoln}></img>
              <img id="carrierLogos" className="div12" src={kclife}></img>
              <img id="carrierLogos" className="div13" src={manLife}></img>
              <img id="carrierLogos" className="div14" src={omaha}></img>
              <img id="carrierLogos" className="div15" src={natLife}></img>
              <img id="carrierLogos" className="div16" src={netWell}></img>
              <img id="carrierLogos" className="div17" src={northAm}></img>
              <img id="carrierLogos" className="div18" src={optimed}></img>
              <img id="carrierLogos" className="div19" src={pivot}></img>
              <img id="carrierLogos" className="div20" src={pram}></img>
              <img id="carrierLogos" className="div21" src={transAm}></img>
              <img id="carrierLogos" className="div22" src={uhone}></img>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Carriers;

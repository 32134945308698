import React, { useState, useEffect } from 'react';
import './scrollingFeature.css';

const ScrollingFeature = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    // Set a timeout to show the element 2 seconds after page load
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFading(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`scroll-down ${isVisible ? 'visible' : ''} ${isFading ? 'hidden' : ''}`}>
      
      <a>↓ ↓ ↓</a>
    </div>
  );
};



export default ScrollingFeature;

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactForm.css";

export const ContactUs = () => {
  const form = useRef();
  const [formPage, setFormPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [fileSize, setFileSize] = useState(null);

  const validateForm = (page) => {
    const currentErrors = {};
    const formElements = form.current.elements;

    if (page === 1) {
      if (!formElements.firstName.value.trim()) {
        currentErrors.firstName = "First Name is required.";
      }
      if (!formElements.lastName.value.trim()) {
        currentErrors.lastName = "Last Name is required.";
      }
      if (
        !formElements.email.value.trim() ||
        !/\S+@\S+\.\S+/.test(formElements.email.value)
      ) {
        currentErrors.email = "Valid Email is required.";
      }
      if (!formElements.phoneNumber.value.trim()) {
        currentErrors.phoneNumber = "Phone Number is required.";
      }
      if (!formElements.bestTimeToCall.value) {
        currentErrors.bestTimeToCall = "Best Time to Call is required.";
      }
      if (!formElements.officePreference.value) {
        currentErrors.officePreference = "Office Preference is required.";
      }
    } else if (page === 2) {
      if (!formElements.message.value.trim()) {
        currentErrors.message = "Message is required.";
      }
      const file = formElements.my_file.files[0];
      if (!file) {
        currentErrors.my_file = "Resume is required.";
      } else if (file.size > 500 * 1024) {
        currentErrors.my_file = "File size exceeds 500KB.";
      }
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSize(file.size);
    } else {
      setFileSize(null);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm(2)) {
      emailjs
        .sendForm("service_76s388m", "template_dncyt2f", form.current, {
          publicKey: "qZfmJR1vYNBv-OJd1",
        })
        .then(
          () => {
            alert("Email sent successfully!");
            resetForm();
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    }
  };

  const resetForm = () => {
    form.current.reset();
    setFormPage(1);
    setErrors({});
    setFileSize(null);
  };

  const goToNextPage = () => {
    if (validateForm(1)) {
      setFormPage(2);
    }
  };

  const goToPreviousPage = () => {
    setFormPage(1);
  };

  return (
    <form
      ref={form}
      encType="multipart/form-data"
      method="post"
      onSubmit={sendEmail}
    >
      <div className={`formPage1 ${formPage === 1 ? "active" : ""}`}>
        <div className="form-group">
          <label>
            First Name{" "}
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}
          </label>
          <input type="text" name="firstName" />
        </div>

        <div className="form-group">
          <label>
            Last Name{" "}
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}
          </label>
          <input type="text" name="lastName" />
        </div>

        <div className="form-group">
          <label>
            Email{" "}
            {errors.email && <span className="error">{errors.email}</span>}
          </label>
          <input type="email" name="email" />
        </div>

        <div className="form-group">
          <label>
            Phone Number{" "}
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
          </label>
          <input type="number" name="phoneNumber" />
        </div>

        <div className="form-group">
          <label>
            Best Time to Call{" "}
            {errors.bestTimeToCall && (
              <span className="error">{errors.bestTimeToCall}</span>
            )}
          </label>
          <select name="bestTimeToCall">
            <option value="">Select</option>
            <option value="Morning">Morning</option>
            <option value="Afternoon">Afternoon</option>
            <option value="Evening">Evening</option>
          </select>
        </div>

        <div className="form-group">
          <label>
            Office Preference{" "}
            {errors.officePreference && (
              <span className="error">{errors.officePreference}</span>
            )}
          </label>
          <select name="officePreference">
            <option value="">Select</option>
            <option value="Tampa">Tampa</option>
            <option value="StPete">Saint Petersburg</option>
          </select>
        </div>

        <button className="formButtons" type="button" onClick={goToNextPage}>
          Next
        </button>
      </div>

      <div className={`formPage2 ${formPage === 2 ? "active" : ""}`}>
        <div className="form-group">
          <label>
            About you{" "}
            {errors.message && <span className="error">{errors.message}</span>}
          </label>
          <textarea name="message" />
        </div>

        <div className="form-group">
          <label>
            Resume (Max size: 500KB){" "}
            {errors.my_file && <span className="error">{errors.my_file}</span>}
          </label>
          <input type="file" name="my_file" onChange={handleFileChange} />
          {fileSize && (
            <span className="file-size">
              File size: {(fileSize / 1024).toFixed(2)} KB
            </span>
          )}
        </div>

        <button
          className="formButtons"
          type="button"
          onClick={goToPreviousPage}
        >
          Back
        </button>
        <input className="formButtons" type="submit" value="Submit" />
      </div>
    </form>
  );
};

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";
import "./Growth.css";
import Layout from "../layout/layout";
import iglogo from "../images/ig.svg";

import group from "../images/group.png";
import group2 from "../images/group2.png";
import ScrollingFeature from "../Components/scrollingFeature";

function Growth() {
  return (
    <Layout>
      <div className="growthMain">
        <div className="growthSplash">
          <div className="growthSplashContainer">
            <div className="growthSplashText">
              <h3>
                Playing it safe and taking no risks is a shortcut to poverty
              </h3>
              <h2>Jordan Belfort</h2>
            </div>
          </div>
        </div>
        <div className="growthInfoContainer">
          <div className="growthInfoTop">
            <h1 className="growthHeading" id="headingStyle">
              What we can offer you
            </h1>
            <div className="growthInfoTopWrapper">
              <div className="growthInfoTopLeft">
                <h2>Unmatched Training from Industry Leaders</h2>

                <a>
                  We take pride in providing our Associates with top-notch
                  training and development opportunities to excel in their
                  roles. Our comprehensive program covers product knowledge,
                  communication skills, sales techniques, and organizational
                  strategies. We have a team of seasoned professionals
                  passionate about sharing their industry knowledge. Our goal is
                  to ensure our Associates are well-equipped to help customers
                  make informed decisions about their insurance needs and
                  achieve their personal and professional goals.
                </a>
              </div>
              <div className="growthInfoTopRight">
                <h2>In buisness for yourself but not by yourself</h2>
                <a>
                  At MedaHealth, we believe in investing in the growth and
                  development of our Associates. We provide ongoing training and
                  support so you can continuously improve your skills and
                  knowledge. We also offer opportunities for career advancement,
                  whether taking on new roles within the company or moving up to
                  leadership positions. With our focus on personal and
                  professional growth, you can build a fulfilling career with
                  MedaHealth that will take you wherever you want to go – at the
                  pace of your performance.
                </a>
              </div>
            </div>
          </div>

          <ScrollingFeature></ScrollingFeature>

          <h1 id="headingStyle">What we look for in new associates</h1>
          <div className="growthMiddleWrapper">
            <div className="growthInfoMiddleContainer">
              <div className="growthInfoMiddle">
                <div className="growthInfoMiddleLeft">
                  <div className="coachability" id="attributesText">
                    <div className="coachabilityTitle" id="logoTitle">
                      <h2>Coachability</h2>
                    </div>
                    <a>
                      Able to take coaching and make the necessary adjustments
                      for your success. Everyone involved in your training has a
                      stake in your success and will help you stay on track and
                      in the money
                    </a>
                  </div>
                  <div className="effort" id="attributesText">
                    <div className="effortTitle" id="logoTitle">
                      <h2>Effort</h2>
                    </div>
                    <a>
                      You have the freedom to create your own schedule and we
                      encourage a balanced lifestyle. However, if you come in
                      part-time – please expect part-time results!
                    </a>
                  </div>
                  <div className="ethics" id="attributesText">
                    <div className="ethicsTitle" id="logoTitle">
                      <h2>Ethics</h2>
                    </div>
                    <a>
                      Ultimately the most important part of this industry is the
                      customer. We train our team to be sure that our clients
                      are put in the best possible situation within their budget
                      so they have a good claims experience at an affordable
                      cost.
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="growthInfoMiddleRight">
              <a href="https://www.instagram.com/pittmanregion_meda/">
                <div className="pittmanRegion">
                  <img className="dannyD" src={group}></img>
                  <div className="rrContent">
                    <h1>Pittman Region</h1>

                    <img className="regionIG" src={iglogo}></img>
                  </div>
                </div>
              </a>
              <a href="https://www.instagram.com/reszkoregion/">
                <div className="rezkoRegion">
                  <img className="dannyD" src={group2}></img>
                  <div className="rrContent">
                    <h1>Reszko Region</h1>

                    <img className="regionIG" src={iglogo}></img>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="growthInfoBottom">
            <h1>Ready to unlock financial freedom?</h1>
            <h3>See if you belong in the MEDAVERSE</h3>
            <div className="growthAssociatesContainer">
              <Link
                to="/contact"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <button>Apply Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Growth;

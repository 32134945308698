import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";

import "./HomePage.css";
import "../Components/scrollingFeature.css";
import AgentTestimonial from "../Components/agentTestimonial";
import ScrollingFeature from "../Components/scrollingFeature";

import leadsPic from "../images/lead.png";
import prodcutsPic from "../images/product.png";
import commisionsPic from "../images/commission.png";
import rickPic from "../images/President-Rick.webp";
import rickSig from "../images/president-signature.webp";
import video1 from "../videos/video1.mp4";

function HomePage() {
  return (
    <Layout>
      <div className="homePageContainer">
        <div className="homeSplash">
          <video
            className="video1"
            src={video1}
            controls={false}
            playsInline
            autoPlay
            loop
            muted
          ></video>
          <div className="homeSplashContent">
            <div className="homeSplashContentTop">
              <h2>Find Out Why We Are Tampa Bay's</h2>
              <h1>Fastest Growing</h1>
              <h3>Insurance Brokerage</h3>
            </div>

            <div className="homeSplashContentBottom">
              <a>
                By agents for agents... Setting the standard in the industry
                with new school energy and old school experience
              </a>
              <Link
                to="/contact"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {" "}
                <button className="applyButton">Apply Now</button>{" "}
              </Link>
            </div>
          </div>

          <ScrollingFeature></ScrollingFeature>
        </div>

        <div className="homePageDifferent">
          <div className="homePageDifferentContainer">
            <div className="homePageDifferentTop">
              <h1>WHAT MAKES US DIFFERENT?</h1>
              <a>
                At MedaHealth we represent the industry's leading carriers
                across the US. You gain access to unlimited company-provided
                leads and earn the highest commission in Tampa Bay of all
                brokerages offering leads. Enjoy a fun office environment with
                daily and weekly bonuses, along with the freedom to create your
                own schedule after the training period. Benefit from 14 carrier
                bonuses in addition to agency profit sharing, and long-term
                residual income on a book of business you own.
              </a>

              <a>
                We train ethically and morally to benefit the customer, and
                you'll be backed by a brokerage that encourages personal growth.
                Plus, you'll have the opportunity to go on exotic company trips
                semi-annually. Don't miss out on this chance to join an
                innovative team committed to moving forward in the industry.
              </a>

              <Link
                to="growth"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <button className="readButton">Read More</button>
              </Link>
            </div>
            <hr></hr>
            <div className="homePageDifferentBottom">
              <div className="agentFeedback">
                <div className="agentFeedbackContainer">
                  <h1>See What Our Agents Have To Say</h1>
                  <AgentTestimonial></AgentTestimonial>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mcInfo">
          <div className="mcInfoTop">
            <div className="mcInfoTopLeft" id="infoMobile">
              <img className="leadsImage" id="infoPics" src={leadsPic}></img>
            </div>
            <div className="mcInfoTopRight">
              <h1>Leads</h1>
              <ul>
                <li>
                  MedaHealth provides qualified and warm leads through their CRM
                  system, eliminating the need for Associates to purchase leads.
                </li>
                <li>
                  Associates can focus on finding the right plans for customers
                  instead of searching for potential clients.
                </li>
                <li>
                  Real-time customer interactions allow Associates to save
                  customers money or increase their benefits.
                </li>
              </ul>
            </div>
          </div>

          <div className="mcInfoMiddle">
            <div className="mcInfoMiddleLeft">
              <h1>Products</h1>
              <ul>
                <li>
                  MedaHealth offers the best insurance options nationwide, with
                  Associates representing top carriers across 43 states.
                </li>
                <li>
                  MedaHealth exclusively works with A-rated carriers, ensuring
                  clients receive trustworthy and high-quality plans.
                </li>
                <li>
                  Associates can sell all available products in-house using
                  their 2-15 Insurance license, without outsourcing.
                </li>
              </ul>
            </div>
            <div className="mcInfoMiddleRight">
              <img
                className="productImage"
                id="infoPics"
                src={prodcutsPic}
              ></img>
            </div>
          </div>
          <div className="mcInfoBottom">
            <div className="mcInfoBottomLeft">
              <img
                className="commisionPic"
                id="infoPics"
                src={commisionsPic}
              ></img>
            </div>
            <div className="mcInfoBottomRight">
              <h1>Commission</h1>
              <ul>
                <li>
                  MedaHealth offers the highest entry-level commissions in Tampa
                  Bay and provides leads for Associates.
                </li>
                <li>
                  Owned by Producers and Directors in the field, MedaHealth
                  values its producers as the most important asset, with no
                  hidden fees.
                </li>
                <li>
                  Commissions can exceed 100% on the highest commission product,
                  offering significant career compensation.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="founderContainer">
          <div className="founderWrap">
            <div className="founderLeft">
              <h2>Message from Founder of MedaHealth Associates Inc.</h2>
              <a>
                Come join our team and build wealth together with the many other
                young men and women that have already begun their journey. There
                are not many other sales opportunities that continue to pay you
                months and even years after the sales process is completed and
                the customers are satisfied!
              </a>
              <img className="rickSignature" src={rickSig}></img>
              <h3>Rick Pertile</h3>
              <a>Founder MedaHealth Associates Inc.</a>
            </div>
            <div className="founderRight">
              <img className="presidentPhoto" src={rickPic}></img>
            </div>
          </div>
        </div>

        <div className="applyNowContainer">
          <div className="applyNowContent">
            <h1>Ready to unlock financial freedom?</h1>
            <h3>See if you belong in the MEDAVERSE</h3>
            <Link
              to="/contact"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              <button className="applyButton">APPLY NOW</button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import surfer from "../images/mc-surfer-icon.svg";
import footerSurfer from "../images/footerLogo.svg";
import fblogo from "../images/fb.svg";
import iglogo from "../images/ig.svg";
import "../layout/layout.css";

const Layout = (props) => {
  const { children } = props;
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const menuBar = document.querySelector(".MenuBar");
      if (window.scrollY > 50) {
        menuBar.classList.add("collapsed");
      } else {
        menuBar.classList.remove("collapsed");
      }
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="Layout">
      <div className="MenuBar">
        <div className="MenuBarLeft">
          <Link to="/">
            <img className="MenuLogo" src={surfer}></img>
          </Link>
        </div>
        <div className="MenuBarRight">
          <div className="MenuBarRightContent">
            <Link to="/" onClick={() => window.scroll(0, 0)}>
              Home
            </Link>
            <Link to="/growth" onClick={() => window.scroll(0, 0)}>
              Growth & Development
            </Link>
            <Link to="/carriers" onClick={() => window.scroll(0, 0)}>
              Our Carriers
            </Link>
            <Link to="/contact" onClick={() => window.scroll(0, 0)}>
              Contact Us
            </Link>
          </div>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            &#9776;
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="MobileMenu" ref={menuRef}>
            <Link
              to="/"
              onClick={() => {
                toggleMobileMenu();
                window.scroll(0, 0);
              }}
            >
              Home
            </Link>
            <Link
              to="/growth"
              onClick={() => {
                toggleMobileMenu();
                window.scroll(0, 0);
              }}
            >
              Development
            </Link>
            <Link
              to="/carriers"
              onClick={() => {
                toggleMobileMenu();
                window.scroll(0, 0);
              }}
            >
              Our Carriers
            </Link>
            <Link
              to="/contact"
              onClick={() => {
                toggleMobileMenu();
                window.scroll(0, 0);
              }}
            >
              Contact Us
            </Link>
            <Link to="/contact" onClick={() => window.scroll(0, 0)}>
              <button id="layoutApplyButton">Apply Now</button>
            </Link>
            <div className="menuIconContainer">
              <a href="https://www.facebook.com/MedaHealth/">
                <img className="menuSocialIcon" src={fblogo}></img>
              </a>
              <a href="https://www.instagram.com/meda.health/">
                <img className="menuSocialIcon" src={iglogo}></img>
              </a>
            </div>
          </div>
        )}
      </div>

      <div>{children}</div>

      <div className="Footer">
        <div className="FooterContent">
          <div className="FooterTop">
            <img className="FooterSurfer" src={footerSurfer}></img>
            <hr></hr>
          </div>
          <div className="FooterMiddle">
            <Link to="/" onClick={() => window.scroll(0, 0)}>
              Home
            </Link>
            <Link to="/growth" onClick={() => window.scroll(0, 0)}>
              Development
            </Link>
            <Link to="/carriers" onClick={() => window.scroll(0, 0)}>
              Carriers
            </Link>
            <Link to="/contact" onClick={() => window.scroll(0, 0)}>
              Contact
            </Link>
          </div>
          <div className="FooterBottom">
            <div className="FooterSocial">
              <a href="https://www.facebook.com/MedaHealth/">
                <img className="socialIcon" src={fblogo}></img>
              </a>
              <a href="https://www.instagram.com/meda.health/">
                <img className="socialIcon" src={iglogo}></img>
              </a>
            </div>
          </div>
        </div>
        <div className="copyBar">
          <a>Copyright © 2023 Meda Career. All Right Reserved. </a>
          <a> Terms & Conditions</a>
          <a>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import React from "react";
import "./Contact.css";
import Layout from "../layout/layout";
import ContactForm from "../Components/contactForm";

import { InstagramEmbed } from "react-social-media-embed";

function Contact() {
  return (
    <Layout>
      <div className="contactContainer">
        <div className="contactTop">
          <div className="contactSplash">
            <h1>
              Contact us to find out if MedaHealth Associates is right for you!
            </h1>
          </div>
        </div>
        <div className="contactBottomContainer">
          <div className="contactBottom">
            <div className="contactBottomLeft">
              <ContactForm></ContactForm>{" "}
            </div>
            <div className="contactBottomRight">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url="https://www.instagram.com/p/C8cE00GOKYy/"
                  width={"auto"}
                />
              </div>
            </div>
          </div>
          <div className="contactBottomInfo">
            <div className="contactUs">
              <h2>Would you rather reach out to us?</h2>
              <h1>PHONE: 813-545-0753</h1>
              <h1>EMAIL: career@mymedahealth.com</h1>
            </div>
            <div className="contactSocial"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;

// App.js
import React, { useState } from "react";
import "./agentTestimonial.css";

const testimonials = [
  {
    picture: require("../images/headShots/austin.jpg"),
    name: "Austin B.",
    title: "Senior Associate",

    fullTestimonial:
      "Being with a company that understands how important the culture is in the cubicles is everything. I took a leap of faith starting with MedaHealth and feel strongly I made the right choice. Strong leadership, vast array of products and an overall entertaining work environment are all major keys to my success in this new industry. Thankful for the time I've spent and all the money I've made.",
  },
  {
    picture: require("../images/headShots/carlie.jpg"),
    name: "Carlie B.",
    title: "Senior Associate",
    fullTestimonial:
      "Working in an environment where I am comfortable, happy, and welcomed is non-negotiable for me, especially if I am working for myself! At MEDA I am surrounded by like-minded, driven individuals who also seek to build each other up for the success of the community. It is extremely uplifting. Not only that, but I am a full time college student, and I would not be able to do that without the opportunity of financial freedom I was blessed to find here!",
  },
  {
    picture: require("../images/headShots/hoff.jpg"),
    name: "Ryan H.",
    title: "Divisional Director",
    fullTestimonial:
      "As a true brokerage, run by agents for agents, MedaHealth provides a full product suite of health and life products across all the major carriers. This enables me to help find my clients the best coverage for their specific needs and budget, regardless of their situation, while saving them money and providing a significant upfront and residual income for me and my family. The culture is inclusive and collaborative, which makes coming to work every day something I genuinely look forward to. I’m proud to be a part of the MedaHealth family!",
  },
  {
    picture: require("../images/headShots/pak.jpg"),
    name: "Stevan P.",
    title: "Senior Associate",
    fullTestimonial:
      "I love people. I love to help people in any way I can. I feel that is my purpose. Because of that, I choose MedaHealth. It was right for my soul because daily I do what I love. Personally, I know the importance of quality healthcare and how it can affect your life. We spend more than half our lives at work, so it’s important to enjoy what we do, and a blessing if you can work alongside like-minded people. Meda gave me all of that and more. I believe we should lead our life by dreams, hope, and aspirations, and controlling what we can control. Daily I am able to provide a service that helps me dream while cultivating my faith and beliefs. As a result, I can control where I want to go financially in life and the type of man I want to be in my personal life.",
  },
  {
    picture: require("../images/headShots/mariah.jpg"),
    name: "Mariah M.",
    title: "Divisional Director",
    fullTestimonial:
      "From the moment I joined the team, I felt welcomed and supported. At MedaHealth Associates the culture is incredibly positive and collaborative, and everyone works together to achieve common goals. One of the things I appreciate most about MedaHealth is the level of investment they take in their agents. They offer a wide range of training and development opportunities, and leadership is always available to provide guidance and support. This has helped me to grow both personally and professionally, and I continuously feel like a valued member of the team. Thank you, MedaHealth, for the wonderful opportunity to work with such an amazing team!",
  },
  {
    picture: require("../images/headShots/sully.jpg"),
    name: "Sully F.",
    title: "Senior Assoicate",
    fullTestimonial:
      "It has been pretty great working for the company. I can say that after working with this group for about 1.5 years, the financial part of it has definitely exceeded expectations (all without buying any extra leads may I add). Being a part of such a nice, chill, and very helpful group...all with the same work mentality makes it very easy to reach whatever goals you may have :)",
  },
];

const TestimonialCard = ({ picture, name, title, blurb, fullTestimonial }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="testimonial-card">
      <div
        className="testimonial-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <img
          src={picture}
          alt={`${name}'s picture`}
          className="testimonial-picture"
        />
        <div className="testimonial-header-text">
          <h3 className="testimonial-name">{name}</h3>
          <p className="testimonial-title">{title}</p>
        </div>
      </div>
      {isExpanded && (
        <div
          className="testimonial-body"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <p className="testimonial-full">{fullTestimonial}</p>
        </div>
      )}
    </div>
  );
};

const AgentTestimonial = () => {
  return (
    <div className="testimonials-container">
      {testimonials.map((testimonial, index) => (
        <TestimonialCard key={index} {...testimonial} />
      ))}
    </div>
  );
};

export default AgentTestimonial;

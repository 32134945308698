import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./components/pages/HomePage";
import Carriers from "./components/pages/Carriers";
import Contact from "./components/pages/Contact";
import Growth from "./components/pages/Growth";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage></HomePage>} />
        <Route path="/carriers" element={<Carriers></Carriers>}></Route>
        <Route path="/contact" element={<Contact></Contact>}></Route>
        <Route path="/growth" element={<Growth></Growth>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
